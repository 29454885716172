$ecogy-blue: #0eb6db;
$ecogy-red: #e22006;
$ecogy-green: #4caf50;
$ecogy-orange: orange;
$ecogy-white: #fff;
$ecogy-black: #000;
$ecogy-dark: #1f1f1f;
$ecogy-grey: #aaaeb3;
$ecogy-palegrey: #e9ecef;
$ecogy-lightgrey: #dae0e7;
$ecogy-mediumgrey: #959ca4;
$ecogy-darkgrey: #343c44;

body {
  background-color: $ecogy-black;
  color: $ecogy-white;
}

.app {
  text-align: center;

  a {
    color: $ecogy-blue;
  }

  .projects-list {
    li {
      padding: 5px;
    }

    li:hover {
      background-color: $ecogy-blue;

      a {
        color: $ecogy-white;
      }
    }
  }

  .identifier {
    color: $ecogy-blue;
  }

  .reading {
    color: $ecogy-blue;
  }
}

.app-header {
  background-color: #1f1f1f;
  height: 60px;
  color: white;
}

.logo {
  height: auto;
  max-height: 60px;
  width: auto;
  max-width: 100%;
}

ul {
  list-style-type: none;
}

.app-link {
  color: #61dafb;
}

.app-logo {
  animation: App-logo-spin infinite 5s linear;
  height: 200px;
}

.loading {
  text-align: center;
  color: $ecogy-grey;
  font-size: xx-large;
}

@keyframes app-logo-spin {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}
